import Spinner from '@/components/spinner';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import Link from '@/components/link';

interface iButtonProps extends React.ComponentProps<typeof Button>  {
    href?: string;
    isDark?: boolean;
    icon?: React.ReactNode;
    block?: boolean;
    processing?: boolean;
    isExternal?: boolean;
}
export default function ({
    children,
    icon,
    className,
    isDark = true,
    block,
    processing,
    href,
    ...props
}: iButtonProps) {
    const getIcon = () => {
        if (processing) {
            return <Spinner />;
        }

        return icon && <span className="mr-2 h-4 w-4">{icon}</span>;
    };

    className = cn(className, block && 'w-full border');

    const variant = props.variant ? props.variant : isDark ? 'default' : 'outline';

    return href ? (
        <Button asChild variant={variant} className={className} disabled={processing}>
            <Link href={href} {...props}>
                {getIcon()}
                {children}
            </Link>
        </Button>
    ) : (
        <Button variant={variant} className={className} disabled={processing} {...props}>
            {getIcon()}
            {children}
        </Button>
    );
}
